import React from "react";
import mainImage from "../../images/MTC_membership.jpg";
import "./styles.css";
const Membership = () => {
  return (
    <div className="page">
      <h1 className="head">Membership</h1>
      <img className="main-img" src={mainImage} alt="mainImage" />
      <div className="text-content">
        <p>
          If you are thinking about joining the club you can come along and join
          in one of our friendly social
          <a href="/playing" title="playing">
            {" "}
            sessions
          </a>{" "}
          where you will be made very welcome.
          <br />
          <br />
          Our membership rates, shown below are amongst the lowest in the local
          local area.
          <br />
          <br />
          <table className="table-main">
            <thead>
              <tr className="table-row table-head">
                <td className="table-member">Membership</td>
                <td className="table-rate">Cost per year</td>
              </tr>
            </thead>
            <tbody>
              <tr className="table-row">
                <td>Family</td>
                <td>£210</td>
              </tr>
              <tr className="table-row">
                <td>Adult</td>
                <td>£115</td>
              </tr>
              <tr className="table-row">
                <td>Senior</td>
                <td>£105</td>
              </tr>
              <tr className="table-row">
                <td>Student</td>
                <td>£37</td>
              </tr>
              <tr className="table-row">
                <td>Under 16</td>
                <td>£31</td>
              </tr>
              <tr className="table-row">
                <td>Under 11</td>
                <td>£16</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          Annual membership runs from April to March. Reduced, pro rata rates
          are available for new members joining part way through the year.
          <br />
          <br />
        </p>
        <br />
        <br /> <h4>Contact the club secretary on 07702 242000</h4>
        <a href="mailto:admin@mistertontennis.co.uk">
          email: admin@mistertontennis.co.uk
        </a>
      </div>
      <br />
      <br />
    </div>
  );
};
export default Membership;
