import React from "react";
import mainImage from "../../images/MTC_Events.jpg";
import "./styles.css";
const Events = () => {
  return (
    <div className="page-events">
      <h1 className="head">What's Happening</h1>
      <h3 className="strap">Upcoming Events</h3>
      <img className="main-img" src={mainImage} alt="mainImage" />
      <table className="table-main">
        <thead>
          <tr className="table-row table-head">
            <td>Event</td>
            <td>Date: time</td>
            <td>Sign-up</td>
          </tr>
        </thead>
        <tbody>
          <tr className="table-row">
            <td className="table-event">Ladies' Doubles Competition</td>
            <td className="table-date">Sunday 18th August: 2.00pm</td>
            <td>
              <a href="https://xoyondo.com/su/bdnpbbv1n57p38u" target="blank">
                <div className=" sign-icon fa-solid fa-user-plus"></div>
              </a>
            </td>
          </tr>
          <tr className="table-row">
            <td className="table-event">Men's' Doubles Competition</td>
            <td className="table-date">Thursday 29th August: 6.00pm</td>
            <td>
              <a href="https://xoyondo.com/su/eoy9wrfr41jgkkb/cz4pqsuts5" target="blank">
                <div className=" sign-icon fa-solid fa-user-plus"></div>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default Events;
