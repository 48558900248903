import axios from "axios";

const token = JSON.parse(sessionStorage.getItem("token"));
const urlBase =
  process.env.NODE_ENV === "production"
    ? "https://mistertontennis-backend.onrender.com"
    : "http://localHost:5000";
const API = axios.create({ baseURL: urlBase });

const auth = sessionStorage.getItem("profile");

API.interceptors.request.use((req, res) => {
  if (token) {
    console.log(token);
    req.headers.Authorization = `bearer ${token}`;
  }
  return req;
});

export const expiredToken = () => API.post("user/checkToken", { token: token });
export const logIn = (formData) => API.post("user/login", formData);
export const register = (formData) => API.post("user/register", formData);
export const sendPwLink = (formData) => API.post("user/forgotPw", formData);
export const changePw = (formData) => API.post("user/changePw", formData);
