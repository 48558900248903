import React from "react";
import "./styles.css"
const Footer = () => {
  return (
    <div className="footer-frame">
      <p className="copyright"> Copyright: Misterton Tennis Club 2024 </p>
      <a className="fa fa-facebook" title="facebook" href="https://www.facebook.com/mistertontennis.co.uk/photos?locale=en_GB"></a>

      <a href="/privacy">See our privacy notice</a>
    </div>
  );
};
export default Footer;
