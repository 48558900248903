import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

//import "rsuite/dist/rsuite.min.css";
//import "./styles/styles.css";
//import reducers from './reducers'
//const store = configureStore({ reducer: reducers })
//const container = document.getElementById("root");
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
